<template>
  <v-card>
    <v-card-title>
      <span class="text-h5"> </span>
    </v-card-title>

    <v-card-text>
      <v-container>
        <v-row>
          <v-col>
            <v-text-field
                v-model="commission"
                label="Commission sur les transactions"
                item-text="label">
              <span
                  slot="append"
              >
                %
              </span>
            </v-text-field>

            <v-select
                label="Selectionnez un status"
                v-model="status_id"
                :items="statues"
                item-value="id"
                item-text="label">
            </v-select>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-btn
          color="error"
          text
          @click="close"
      >
        Fermer
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
          color="primary"
          dark
          small
          @click="save"
      >
        <v-icon
            left
            dark
            small
        >
          mdi-content-save-check
        </v-icon>
        Enregistrer
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {Drequest} from "../plugins/Drequest";

export default {
  name: "AffiliateDetail",
  props: ["instanceaffiliate", "statues"],
  data: () => ({
    affiliate: {},
    status_id: "",
    commission: 0
  }),
  mounted() {
    this.status_id = this.instanceaffiliate.status.id
    this.affiliate = this.instanceaffiliate;
    this.commission = this.affiliate.commission
  },
  methods: {
    save() {
      Drequest.api("affiliate.validate?id="+this.affiliate.id).data({
        affiliate: {"status.id": this.status_id, "commission": this.commission}
      }).raw((response) => {
        console.log(response);
        window.location.reload();
      })
    },
    close() {
      this.dialog = false
    }
  }
}
</script>