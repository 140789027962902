<template>
  <div>
    <v-row>
      <v-col cols="8" xs="12">
        <breadcrumbs :items="dataBreadcrumbs" />
      </v-col>
      <v-col cols="4" xs="12" align="right">
      </v-col>
    </v-row>
    <v-row>
      <v-col xs="12" class="col-xs-12">
        <h2 style="font-size: 25px !important;">Liste des Affiliations</h2>
      </v-col>
      <v-col xs="12" class="col-xs-12">
        <v-btn
            color="primary"
            small
            dark
            class="mb-2 float-right"
            @click="dialogLivreur = true"
        >
          <v-icon right>
            mdi-plus-circle
          </v-icon>
          Ajouter
        </v-btn>
      </v-col>
    </v-row>
    <loaderdialog :loader="{text: textLoader, power: loadingprocess}" v-if="loadingprocess"/>
    <v-dialog
        v-model="dialogLivreur"
        transition="dialog-top-transition"
        max-width="500"
    >
      <template>
        <v-card>
          <v-card-title class="text-h5 red lighten-3">
            Ajouter un livreur
          </v-card-title>
          <v-card-text>
            Rechercher un utilisateur a partir de son email
          </v-card-text>
          <v-card-text>
              <v-row>
                <v-col cols="8" xs="12">
                  <v-text-field
                      dense
                      v-model="email"
                      :rules="emailRules"
                      color="black"
                      label="E-mail"
                      :disabled="alertProcess"
                      placeholder="Entrer l'email du personnel"
                  ></v-text-field>
                </v-col>
                <v-col cols="4" xs="12" align="right">
                  <v-btn
                      small
                      :disabled="alertProcess"
                      color="primary"
                      @click="checkUser"
                  >
                    Rechercher
                    <v-icon>
                      mdi-database-search
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          <v-alert
              v-if="alertProcess"
              :color="alert.color"
              text
              class="mb-0"
          >
            <div class="d-flex align-start">
              <v-icon :color="alert.color">
                {{ alert.icon }}
              </v-icon>

              <div class="ms-3">
                <p class="text-base font-weight-medium mb-1">
                  {{alert.text}}
                </p>
              </div>
            </div>
          </v-alert>
          <v-divider></v-divider>
          <v-expand-transition>
            <v-list
                v-if="modelEmail.status"
                class="red lighten-3"
            >
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title v-text="modelEmail.name"></v-list-item-title>
                  <v-list-item-subtitle v-text="modelEmail.email"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-expand-transition>
          <v-card-actions>
            <v-btn
                color="error"
                text
                @click="close"
            >
              Fermer
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                v-if="!alertProcess"
                small
                :disabled="!modelEmail.status"
                color="primary"
                @click="addAffiliate"
            >
              Ajouter
              <v-icon right>
                mdi-plus-circle
              </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <!-- actions -->
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <!-- search -->
      <v-row>
        <v-col lg="9">
          <v-text-field v-model="searchQuery" label="Filtrer"></v-text-field>
        </v-col>
        <!-- v-col lg="2">
          <v-select
              label="Statut"
              v-model="search.status"
              :items="statues"
              item-value="id"
              item-text="label"
              @change="fetchItems"
          ></v-select>
        </v-col -->
        <v-col lg="3">
          <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="interval.date1"
                  label="Enregistré le"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="interval.date1"
                @input="menu2 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>

      </v-row>

      <v-spacer></v-spacer>

      <div class="d-flex align-center flex-wrap">

      </div>
    </v-card-text>
    <v-dialog
        v-model="dialogAffiliate"
        max-width="500px"
    >
      <AffiliateDetail
          :statues="statues"
          :instanceaffiliate="editedItem" />
    </v-dialog>
    <v-data-table
        :loading="loading"
        :headers="headers"
        :items="affiliatell.listEntity"
        class="elevation-0"
        :page.sync="affiliatell.current_page"
        :items-per-page="affiliatell.per_page"
        :sort-by="['id']"
        @page-count="pageCount = $event"
        hide-default-footer
        :sort-desc="true"
    >

      <template v-slot:item.status="{ item }">
        <v-chip
            :color="item.status.color"
            :class="`v-chip-light-bg text-sm font-weight-semibold  text-capitalize`"
        >{{ item.status.label }}</v-chip>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn
            v-if="!detailCourse"
            small
            color="primary"
            depressed
            :to="'/settings/affiliate/'+item.id"
        >
          <v-icon left>
            {{ icons.mdiEye }}
          </v-icon>
          Consulter
        </v-btn>
        <v-chip

            v-if="detailCourse && verify(item, order)"
            color="#ffc107"
            class="v-chip-light-bg text-xl text-white font-weight-semibold  text-uppercase"
        >Colis Affecté</v-chip>
        <v-btn
            :loading="loadingAffectation"
            :disabled="disableAffectation"
            v-if="detailCourse && (order.affiliate_id != item.id)"
            @click="confirmAssign(item, order)"
            small
            class="primary">
          <v-icon
              small
              class="mr-2"
          >
            mdi-plus
          </v-icon>
          Affecter
        </v-btn>
      </template>
    </v-data-table>
    <v-card-text class="pt-2">
      <v-row>
        <v-col
            lg="2"
            cols="3"
        >

          <v-select
              v-model="affiliatell.per_page"
              label="Lignes par page:"
              :items="[10,20,30,50,80,100, 'Toutes']"
              hide-details
              @change="fetchItems()"
          ></v-select>

        </v-col>

        <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
        >
          <v-pagination
              v-model="affiliatell.current_page"
              total-visible="6"
              :length="parseInt(affiliatell.pagination )"
              @input="fetchItems()"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>
<script>
import loaderdialog from "../../components/ui/loaderdialog";
import breadcrumbs from "../../components/ui/breadcrumbs";
const moonLanding = new Date();
import {Drequest} from "../../plugins/Drequest";
import AffiliateDetail from "../../components/AffiliateDetail";
import {
  mdiEye,
    mdiThumbUp,
    mdiThumbDown
} from '@mdi/js'
export default {
  name: "affiliate-manager",
  components: {AffiliateDetail, loaderdialog, breadcrumbs},
  props: ["user","detailCourse","order"],
  data: () => ({
    alert: {
      icon: null,
      text: null,
      color:"warning"
    },
    alertProcess: false,
    valid: false,
    email: '',
    emailRules: [
      v => !!v || 'E-mail est obligatoire',
      v => /.+@.+/.test(v) || 'E-mail n\'est pas valide',
    ],
    descriptionLimit: 60,
    entries: [],
    isLoading: false,
    modelEmail: {
      id: null,
      name:null,
      email: null,
      status: false,
      affiliate_id: null
    },
    textLoader: 'Chargement ...',
    search: null,
    loadingprocess: false,
    affiliateItems: [],
    icons: {
      mdiEye,
      mdiThumbUp,
      mdiThumbDown
    },
    interval: {
      date1: moonLanding.getFullYear() + "-01-01",
      date2: moonLanding.getFullYear() + "-12-31"
    },
    livreur: '',
    searchQuery: "",
    menu2: false,
    menu3: false,
    loading: false,
    disableAffectation: false,
    loadingAffectation: false,
    dialogLivreur: false,
    dialogAffiliate: false,
    dialogDelete: false,
    headers: [
      {
        text: 'Nom',
        align: 'start',
        sortable: false,
        value: 'user.email',
      },
      {text: 'Status', value: 'status'},
      {text: 'Enregistré le', value: 'created_at'},
      {text: 'Actions', value: 'actions', sortable: false},
    ],
    //items: [],
    statues: [],
    affiliatell:[],
    editedIndex: -1,
    editedItem: {
      name: '',
    },
    defaultItem: {
      name: '',
    },
   // tab: {},
    transporter: {},
  }),
  methods: {
    checkUser(){
      this.loadingprocess = true;
      Drequest.api("dcollection")
          .data({
            "lazyloading.user": "dfilters=on&user.email:eq="+this.email,
          })
          .raw((response) => {
            if(response.user_ll.success){
             let data = response.user_ll.listEntity[0];
             if(data){
               this.modelEmail = {
                 name: 'Nom: '+data.firstname+' '+data.lastname,
                 status: response.user_ll.success,
                 email: 'Email: '+data.email,
                 affiliate_id: data.affiliate_id,
                 id: data.id
               }
             }else{
               this.alertProcess = true;
               this.alert = {
                 icon: this.icons.mdiThumbDown,
                 text: "cet email n'existe pas",
                 color: "error"
               }
               this.loadingprocess = false
             }
            }
          })
      this.loadingprocess = false;
    },
    addAffiliate(){
      this.textLoader = "Traitement ...";
      this.loadingprocess = true;
      this.modelEmail.status = false
      Drequest
          .api("update.affiliate?id="+this.modelEmail.affiliate_id)
          .data({"affiliate":{"user.id": this.modelEmail.id, "transporter.id": this.$store.state.transporter.id}})
          .raw((response)=>{
            if(response.success){
              this.alertProcess = true;
              this.alert = {
                icon: this.icons.mdiThumbUp,
                text: "Enregistrement Reussie",
                color: "success"
              }
              this.initialize(this.$store.state.transporter.id);
              //this.close();
            }else{
              this.alert = {
                icon: this.icons.mdiThumbDown,
                text: "Echec de l'Enregistrement",
                color: "error"
              }
            }
          })
          //this.loadingprocess = false;
    },
    verify(item, order){
      if(order.affiliate_id == item.id){
        this.disableAffectation = true;
        return true
      }
      return false;
    },
    confirmAssign(affiliate, order) {
    this.loadingAffectation = true
    Drequest.api("update.order?id="+order.id).data({"order":{"affiliate.id": affiliate.id}}).raw((response)=>{
      console.log(response);
    })
    this.loadingAffectation = false
  },
    confirmUnassign(order) {
      this.loadingAffectation = true
      Drequest.api("update.order?id="+order.id).data({"order":{"affiliate.id": null}}).raw((response)=>{
        console.log(response);
      })
      this.loadingAffectation = false
    },
    fetchAffiliates() {
      this.loadingprocess = true;
      Drequest.api("lazyloading.affiliate")
          .param({
            next: '1',
            per_page: 'all',
          })
          .get((response) => {
            response.listEntity.forEach(item => {
              this.affiliateItems.push({
                text: 'Nom: '+item.user.firstname +' '+item.user.lastname +' Email: '+item.user.email,
                id: item.id,
              });
            })
            this.dialogLivreur = true;
            this.loadingprocess = false;
          })
    },
    initialize(id) {
      this.loading = true
        Drequest.api("dcollection")
          .data({
            "lazyloading.affiliate": "dfilters=on&transporter.id:eq="+id,
            "lazyloading.status": "dfilters=on&entity.name:eq=affiliate",
          })
          .raw((response) => {
            this.loading = false;
            this.affiliatell = response.affiliate_ll;
            this.statues = response.status_ll.listEntity;

            // this.close();
          })

    },
    close() {
      this.alertProcess = false
      this.dialogLivreur = false
      this.loadingprocess = false
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
  },
  watch: {
    dialogLivreur(val) {
      val || this.close()
    },
    loadingprocess(val) {
      if(val){
        this.fetchAffiliates()
      }
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },
  beforeMount() {
    this.dataBreadcrumbs = [
      {
        text: 'Accueil',
        disabled: false,
        href: '/dashboard',
      },
      {
        text: 'Gestion des Affiliations',
        disabled: true,
        href: '/',
      }
    ];
    let transporter = this.$store.state.transporter;
    this.initialize(transporter.id);
    this.transporter = transporter;
  },
  mounted() {
    //this.tab = this.items[0];
  }
}
</script>
<style>
.v-overlay {
  z-index: 201 !important;
}
</style>